import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <div className="flex flex-col sm:flex-row gap-16 sm:gap-0 items-center text-center sm:text-left p-4 sm:p-32 sm:h-[calc(100vh-128px)]">
        <div className="sm:basis-1/2 flex flex-col w-full ">
          <h1 className="text-3xl sm:text-6xl font-bold">
            Boost Your Business, Increase Sales & Reach a Wider Audience
          </h1>
          <p className="text-lg sm:text-3xl font-normal mt-4 text-gray-700">
            Creating Intuitive Software Solutions for Businesses of All Sizes
          </p>
          <a
            href="https://www.fiverr.com/share/Kodo2k"
            target="_blank"
            rel="noreferrer"
          >
            <button
              type="button"
              className="text-white bg-[#2E6149] hover:bg-[#203f2e] focus:ring-4 focus:outline-none focus:ring-[#6ce0a1] font-semibold rounded-lg text-lg px-6 py-3.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-4"
            >
              Schedule a free consultation
            </button>
          </a>
        </div>
        <div className="sm:basis-1/2 sm:w-full flex flex-col items-center justify-center">
          <StaticImage
            src="../images/hero-image.png"
            alt="Robot working with laptop"
            className="rounded-lg w-2/3"
          ></StaticImage>
          <span className="mt-2 italic text-gray-600">
            Created with{" "}
            <a
              className="font-bold"
              target="_blank"
              href="https://www.midjourney.com/home/"
              rel="noreferrer"
            >
              Midjourney
            </a>
          </span>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
